export default (...args) => {
  if (typeof window.ga !== 'function') {
    return console.warn(
      'Warning:',
      'Did not track on Google Analytics, since "window.ga" is not a function',
    );
  }

  window.ga(...args);
};
