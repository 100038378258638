import React from 'react';
import { render } from 'react-dom';
import { ThemeProvider } from '@zendeskgarden/react-theming';

import VideoModal from '../components/VideoModal';

import '@zendeskgarden/react-modals/dist/styles.css';

render(
  <ThemeProvider>
    <VideoModal />
  </ThemeProvider>,
  document.querySelector('#video-modal-container'),
);
