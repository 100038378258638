import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import trackGoogleAnalytics from '../../lib/trackGoogleAnalytics';

const VideoModal = () => {
  const [isVideoVisible, setVideoVisible] = useState(true);
  window.showVideo = () => setVideoVisible(true);

  useEffect(
    () => {
      if (isVideoVisible) {
        trackGoogleAnalytics('send', {
          hitType: 'event',
          eventCategory: 'Video',
          eventAction: 'view',
          eventLabel: 'Video opened for viewing',
        });
      }

      document.querySelector('#video-container').style.display = isVideoVisible
        ? 'none'
        : 'flex';
    },
    [isVideoVisible],
  );

  return (
    <Modal
      style={{
        maxWidth: '90vw',
        maxHeight: '90vh',
        width: 1670,
        height: 1080,
      }}
      visible={isVideoVisible}
      title="Barefoot luxury at its best"
      onSubmit={() => setVideoVisible(false)}
      body={
        <video
          preload="auto"
          style={{ width: '100%', height: '100%' }}
          autoPlay
          controls
        >
          <source
            src="wp-content/themes/talalla/video/front-page-orignal.mp4"
            type="video/mp4"
          />
        </video>
      }
    />
  );
};

export default VideoModal;
