import React from 'react';
import PropTypes from 'prop-types';

import {
  Modal,
  Header,
  Footer,
  FooterItem,
  Body,
} from '@zendeskgarden/react-modals';

const TalallaModal = ({
  visible,
  title,
  body,
  submitButtonText,
  onSubmit,
  style,
}) => {
  return visible ? (
    <Modal style={style}>
      <Header>{title}</Header>
      <Body>{body}</Body>
      <Footer>
        <FooterItem>
          <button className="button" onClick={onSubmit}>
            {submitButtonText}
          </button>
        </FooterItem>
      </Footer>
    </Modal>
  ) : null;
};

TalallaModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  visible: PropTypes.bool,
  submitButtonText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  style: PropTypes.object,
};

TalallaModal.defaultProps = {
  submitButtonText: 'Close',
  visible: false,
};

export default TalallaModal;
